








































































































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import axios from "axios";
import { Notify } from 'vant';

// import table1 from './table1.vue';
// import table2 from './table2.vue';
// import table3 from './table3.vue';

const year = moment().year()

export default Vue.extend({
    components: {
        // table1,
        // table2,
        // table3,
    },
    data() {
        return {
            obj: {
                timeBeforeGrow: '1月1日～2月29日',
                timeGrow: '3月1日～20日',
                timePick: '茶叶采摘期气温日较差日变化图',
                // batchName: '',
                // dateStart: null,
                // dateEnd: null,
                // datePick: null,
                summary: '',
                comment: '',
                // imagesLoader: [],
                imagesUrl: [],
                images: [],
                data: {
                    budGrowthTxt: '1月1日～2月29日平均气温偏高，总降水量与常年比偏多，日照时数偏多，平均相对湿度偏大,极端最低气温-6.3℃，休眠期气候条件较有利，没有明显低温、干旱等灾害。气象条件见表1。',
                    vigorousGrowthTxt: '茶叶采摘前20天气象条件对茶叶品质和产量影响较大。3月1～20日期间，茶园平均气温比同期偏高3.0℃，总降水量偏少82%，日照时数偏多，相对湿度持平，极端最低气温偏高，≥0.1毫米的雨日与常年同期比偏少，≥10℃积温略偏多。但前期降水偏多，茶园墒情较好，加上热量条件较好，使今年茶叶萌动提前，气候条件对当年茶叶的品质和产量形成较有利。气象条件见表2、图1。',
                    pickingTxt: '采摘期3月21～4月25日日平均气温11.5℃，比同期偏低0.7℃，总降水量54.3毫米，与历年同期比偏多23.9%，日照时数250.4小时，偏多89.3，相对湿度69%，偏大4.8%；≥0.1毫米的雨日共3天，气温日较差12.0℃。总之，茶叶采摘期降水偏多，气温偏低，湿度较大，气象条件能够满足茶叶萌芽及生长需求，有利于早春茶叶采收。气象条件见图2。',
                    Analysis1: '茶园茶叶关键生育期（3.10～4.25日）平均气温与历年同期比偏高0.8℃，降水量持平，相对湿度与常年持平，日照偏多，≥0.1mm的雨日数偏多3天，≥10℃有效积温接近正常年份，气温日较差略偏大。总之，今年茶叶关键生育期内降水显著偏多，气温、湿度及光照与常年持平，茶园的茶叶品质和产量较好。',
                    Analysis2: '自去年春茶采摘后至今年春茶采摘前（去年6月至今年4月中旬），茶树生长期间无连续5天及5天以上超过35℃的高温热害，无伏旱发生，去年秋季有一次弱秋淋天气，冬季整体气温偏高，没有出现阶段性严寒天气，今年春季降水偏多，茶园没有出现明显干旱，虽然3月底出现了低温雨雪冻害天气，但茶园大部分茶叶没有萌芽，对茶叶生长影响较小。整个生育期内气象灾害较少，气象条件有利于今年优质茶叶品质和产量形成。',
                    Analysis3: '有机茶生长期内，气温偏高，降水偏多，气象灾害少，危害轻，气候资源较好，对今年茶叶品质提升和产量形成特别有利，资料统计和实地调查表明今年茶叶长势较好，品质达到特优等级。',
                }as any,
            }as any,
        }
    },
    async created() {
        // console.log(this.$route)
        // this.state = this.$route.params.state
        // if(this.state) {
        //     this.getData()
        // }
    },
    methods: {
        getData() {
            console.log(11)
            const id = this.$route.params.id
            // request.get(`/api/tea/rest_tracing_source/detail/${id}`).then(data => {
            //     console.log(data)
            //     const dateStartArr = [], dateEndArr = [], dateArr = []
            //     dateStartArr.push(String(data.teaStartTime).slice(0,4))
            //     dateStartArr.push(String(data.teaStartTime).slice(4,6))
            //     dateEndArr.push(String(data.teaEndTime).slice(0,4))
            //     dateEndArr.push(String(data.teaEndTime).slice(4,6))
            //     dateArr.push(String(data.pickTime).slice(0,4))
            //     dateArr.push(String(data.pickTime).slice(4,6))
            //     dateArr.push(String(data.pickTime).slice(6,8))
            //     data.dateStart = dateStartArr.join('-')
            //     data.dateEnd = dateEndArr.join('-')
            //     data.datePick = dateArr.join('-')
            //     const imagesUrl = []
            //     for (const i of data.images) {
            //         const imgUrl = `${request.BaseUrl}/tea_imgs${i.imgUrl}`
            //         imagesUrl.push(imgUrl)
            //     }
            //     data.imagesUrl = imagesUrl
            //     this.setData({obj: data})
            // })

        },
    },
})
